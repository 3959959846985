html {
  background: var(--amber-color-background-primary, #F2F2F2);
  overflow: hidden;
}
html body {
  background: transparent;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: normal;
  color: var(--amber-color-font-regular, #3D3D3D);
}
html,
body,
.container-fluid,
.site-content,
.view-container {
  height: 100%;
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.container-fluid[drag-drop-drop-target] {
  position: static;
}
.loading-message {
  text-align: center;
  position: relative;
  top: 50%;
  margin-top: -10px;
  font-size: 20px;
  color: var(--amber-color-foreground-muted, #585250);
  letter-spacing: 1px;
}
[ng-cloak],
.ng-cloak {
  display: none !important;
}
